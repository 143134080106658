@import './vendor/normalize.css';
@import './vendor/Fonts/GoogleFonts.css';
@import './scss/shared';

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: $color--global-gray;
  border-radius: 5px;
}

a {
  text-decoration: none;
  color: $color--global-text;
}

p {
  margin-bottom: 0.5rem !important;
}

ul {
  list-style: none;
  // margin: 0 auto !important;
}

button {
  appearance: none;
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  appearance: none;
  outline: none;
  cursor: pointer;
}

html,
body {
  height: 100vh;
  min-width: 200px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  font-family: 'JetBrains Mono', 'Fira Code', 'Montserrat', 'Inter', 'Segoe UI',
    Arial, Helvetica, sans-serif, -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  background-color: $color--global-background- !important;
  scroll-behavior: smooth;
  color: $color--global-text;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  scrollbar-gutter: stable;

  header,
  main,
  footer {
    width: 100%;
    margin: 0 auto;
  }

  input,
  textarea,
  select {
    appearance: none;
    border: none;
    color: $color--global-text;

    &:focus {
      outline: none;
      transition: all 0.2s ease-in-out;

      &::placeholder {
        color: transparent;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
