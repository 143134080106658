@import "../scss/shared";

// Глобальные стили
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
  max-width: 1440px;
  width: 90%;
}

.highlighted-text-shadow {
  @include highlighted-text($color--global-green);
  text-shadow: 0 0 5px rgba(152, 206, 0, 0.5), 0 0 10px rgba(152, 206, 0, 0.5),
    0 0 15px rgba(152, 206, 0, 0.5);
}

.form-control {
  border: none;
  border-bottom: 1px solid $color--global-text;
  border-radius: 0px;
  color: $color--global-text;
  background: transparent;

  &:focus,
  &:hover {
    box-shadow: none;
    color: $color--global-text;
    background: transparent;
    border-color: #ffdf00;
  }

  &:disabled {
    background: transparent;
    box-shadow: none;
    opacity: 1;
  }
}

#message-area {
  resize: none;
}
