@import '../../scss/_shared.scss';

.social {
  left: 1.5%;
  bottom: 0%;
  position: fixed;
  float: left;
}

.social__wrapper {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}

.social__wrapper::after {
  content: '';
  display: block;
  width: 1px;
  height: 100px;
  margin: 0px auto;
  background-color: $color--global-text;
}

.social__icon {
  width: 30px;
  transition: transform 0.2s ease-in-out;
}

.social__icon:hover {
  transform: rotate(10deg);
}

.social__icon:hover:not(:active) {
  transform: rotate(10deg) initial;
}

@media screen and (max-width: 1024px) {


}

@media screen and (max-width: 992px) {
  .social__wrapper {
    gap:3vh;
  }

  .social__wrapper::after {
    height: 60px;
  }
}

@media screen and (max-width: 425px) {
  .social__wrapper::after {
    height: 40px;
  }
}