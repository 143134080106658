@import "../../scss/_shared.scss";

.highlighted-text-nav {
  @include highlighted-text(#989898);
}

.highlighted-text-nav,
.resume {
  @media (min-width: 992px) {
    color: $color--global-text !important;
  }
}

.header {
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgb(22 22 22 / 20%);
  backdrop-filter: blur(10px);

  &__nav-item {
    font-family: "JetBrains Mono";
    font-weight: 400;
    color: $color--global-text !important;

    @media (max-width: 992px) {
      font-size: 2.5vh !important;
    }

    &:hover {
      color: #98ce00 !important;
    }
  }

  &__nav-button {
    @include button-submit-small;
    border-radius: 5px;
    margin: 0;
    min-height: unset;

    @media (max-width: 992px) {
      background-color: transparent;
    }

    &:hover {
      color: $color--global-text !important;
    }
  }

  &__decor-brace {
    color: #989898;
    font-family: "JetBrain Mono";
    font-size: 4vh;
    position: relative;
    bottom: 0.5vh;
    padding-right: 0.5vw;
    padding-left: 0.5vw;

    @media (max-width: 992px) {
      display: none;
    }
  }
}

.active {
  color: $color--global-green !important;
}

.hidden {
  opacity: 0;
  transform: translateY(-100%);
  pointer-events: none;
}

.navbar-toggler {
  border: none !important;

  &:focus {
    box-shadow: none !important;
  }

  &-icon {
    background-image: url(../../images/burger.svg) !important;

    &:active {
      transform: scale(0.9);
    }
  }
}
